/*-- -------------------------- -->
<---     Mobile Navigation      -->
<--- -------------------------- -*/
/* Mobile - 1023px */
@media only screen and (max-width: 63.9375rem) {
    body.cs-open {
      overflow: hidden;
    }
    #cs-navigation {
      width: 100%;
      /* prevents padding and border from affecting height and width */
      box-sizing: border-box;
      padding: 0.75rem 1rem;
      background-color: #fff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      position: fixed;
      z-index: 10000;
    }
    #cs-navigation:before {
      content: "";
      width: 100%;
      height: 0vh;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: -1100;
      transition: height 0.5s, opacity 0.5s;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
    #cs-navigation.cs-active:before {
      height: 150vh;
      opacity: 1;
    }
    #cs-navigation.cs-active .cs-ul-wrapper {
      opacity: 1;
      transform: scaleY(1);
      transition-delay: 0.15s;
    }
    #cs-navigation.cs-active .cs-li {
      opacity: 1;
      transform: translateY(0);
    }
    #cs-navigation .cs-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    #cs-navigation .cs-logo {
      width: 40%;
      max-width: 9.125rem;
      height: 100%;
      margin: 0 auto 0 0;
      /* prevents padding and border from affecting height and width */
      box-sizing: border-box;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;

      padding-left: 70px;
    }
    #cs-navigation .cs-logo img {
      width: 100%;
      height: 100%;
      /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
      object-fit: contain;
    }
    #cs-navigation .cs-toggle {
      /* 44px - 48px */
      width: clamp(2.75rem, 6vw, 3rem);
      height: clamp(2.75rem, 6vw, 3rem);
      margin: 0 0 0 auto;
      background-color: transparent;
      border: none;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #cs-navigation .cs-active .cs-line1 {
      top: 50%;
      transform: translate(-50%, -50%) rotate(225deg);
    }
    #cs-navigation .cs-active .cs-line2 {
      top: 50%;
      transform: translate(-50%, -50%) translateY(0) rotate(-225deg);
      transform-origin: center;
    }
    #cs-navigation .cs-active .cs-line3 {
      opacity: 0;
      bottom: 100%;
    }
    #cs-navigation .cs-box {
      /* 24px - 28px */
      width: clamp(1.5rem, 2vw, 1.75rem);
      /* 14px - 16px */
      height: clamp(0.875rem, 1.5vw, 1rem);
      position: relative;
    }
    #cs-navigation .cs-line {
      width: 100%;
      height: 2px;
      background-color: #1a1a1a;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    #cs-navigation .cs-line1 {
      top: 0;
      transition: transform 0.5s, top 0.3s, left 0.3s;
      animation-duration: 0.7s;
      animation-timing-function: ease;
      animation-direction: normal;
      animation-fill-mode: forwards;
      transform-origin: center;
    }
    #cs-navigation .cs-line2 {
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: top 0.3s, left 0.3s, transform 0.5s;
      animation-duration: 0.7s;
      animation-timing-function: ease;
      animation-direction: normal;
      animation-fill-mode: forwards;
    }
    #cs-navigation .cs-line3 {
      bottom: 0;
      transition: bottom 0.3s, opacity 0.3s;
    }
    #cs-navigation .cs-ul-wrapper {
      width: 100%;
      height: auto;
      padding-bottom: 2.4em;
      background-color: #fff;
      box-shadow: inset rgba(0, 0, 0, 0.2) 0px 8px 24px;
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: -1;
      overflow: hidden;
      transform: scaleY(0);
      transition: transform 0.4s, opacity 0.3s;
      transform-origin: top;
    }
    #cs-navigation .cs-ul {
      width: 100%;
      height: auto;
      max-height: 65vh;
      margin: 0;
      padding: 3rem 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;
      overflow: scroll;
    }
    #cs-navigation .cs-li {
      text-align: center;
      list-style: none;
      width: 100%;
      margin-right: 0;
      opacity: 0;
      /* transition from these values */
      transform: translateY(-4.375rem);
      transition: transform 0.6s, opacity 0.9s;
    }
    #cs-navigation .cs-li:nth-of-type(1) {
      transition-delay: 0.05s;
    }
    #cs-navigation .cs-li:nth-of-type(2) {
      transition-delay: 0.1s;
    }
    #cs-navigation .cs-li:nth-of-type(3) {
      transition-delay: 0.15s;
    }
    #cs-navigation .cs-li:nth-of-type(4) {
      transition-delay: 0.2s;
    }
    #cs-navigation .cs-li:nth-of-type(5) {
      transition-delay: 0.25s;
    }
    #cs-navigation .cs-li:nth-of-type(6) {
      transition-delay: 0.3s;
    }
    #cs-navigation .cs-li:nth-of-type(7) {
      transition-delay: 0.35s;
    }
    #cs-navigation .cs-li:nth-of-type(8) {
      transition-delay: 0.4s;
    }
    #cs-navigation .cs-li:nth-of-type(9) {
      transition-delay: 0.45s;
    }
    #cs-navigation .cs-li:nth-of-type(10) {
      transition-delay: 0.5s;
    }
    #cs-navigation .cs-li:nth-of-type(11) {
      transition-delay: 0.55s;
    }
    #cs-navigation .cs-li:nth-of-type(12) {
      transition-delay: 0.6s;
    }
    #cs-navigation .cs-li:nth-of-type(13) {
      transition-delay: 0.65s;
    }
    #cs-navigation .cs-li-link {
      /* 16px - 24px */
      font-size: clamp(1rem, 2.5vw, 1.5rem);
      line-height: 1.2em;
      text-decoration: none;
      margin: 0;
      color: var(--headerColor);
      display: inline-block;
      position: relative;
    }
    #cs-navigation .cs-li-link:before {
      /* active state underline */
      content: "";
      width: 100%;
      height: 1px;
      background: currentColor;
      opacity: 1;
      display: none;
      position: absolute;
      bottom: -0.125rem;
      left: 0;
    }
    #cs-navigation .cs-li-link.cs-active:before {
      display: block;
    }
    #cs-navigation .cs-button-solid {
      display: none;
    }
  }
  /*-- -------------------------- -->
  <---     Desktop Navigation     -->
  <--- -------------------------- -*/
  /* Small Desktop - 1024px */
  @media only screen and (min-width: 64rem) {
    #cs-navigation {
      width: 100%;
      /* prevents padding and border from affecting height and width */
      box-sizing: border-box;
      padding: 0 1rem;
      background-color: #fff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      position: fixed;
      z-index: 10000;
    }
    #cs-navigation .cs-container {
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.5rem;
    }
    #cs-navigation .cs-toggle {
      display: none;
    }
    #cs-navigation .cs-logo {
      width: 18.4%;
      max-width: 21.875rem;
      height: 4.0625rem;
      /* margin-right auto pushes everything away from it to the right */
      margin: 0 auto 0 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }
    #cs-navigation .cs-logo img {
      width: 100%;
      height: 100%;
      /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
      object-fit: contain;
    }
    #cs-navigation .cs-ul {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      /* 20px - 36px */
      gap: clamp(1.25rem, 2.6vw, 2.25rem);
    }
    #cs-navigation .cs-li {
      list-style: none;
      padding: 2rem 0;
      /* prevent flexbox from squishing it */
      flex: none;
    }
    #cs-navigation .cs-li-link {
      /* 14px - 16px */
      font-size: clamp(0.875rem, 1vw, 1rem);
      line-height: 1.5em;
      text-decoration: none;
      margin: 0;
      color: var(--bodyTextColor);
      display: block;
      position: relative;
    }
    #cs-navigation .cs-li-link:hover:before {
      width: 100%;
    }
    #cs-navigation .cs-li-link.cs-active:before {
      width: 100%;
    }
    #cs-navigation .cs-li-link:before {
      /* active state underline */
      content: "";
      width: 0%;
      height: 2px;
      background: var(--primary);
      opacity: 1;
      display: block;
      position: absolute;
      bottom: 0rem;
      left: 0;
      transition: width 0.3s;
    }
    #cs-navigation .cs-button-solid {
      font-size: 1rem;
      font-weight: 700;
      /* 46px - 56px */
      line-height: clamp(2.875em, 5.5vw, 3.5em);
      text-align: center;
      text-decoration: none;
      min-width: 9.375rem;
      margin: 0;
      /* prevents padding from adding to the width */
      box-sizing: border-box;
      padding: 0 1.5rem;
      color: #fff;
      background-color: var(--primary);
      border-radius: 0.25rem;
      display: inline-block;
      position: relative;
      z-index: 1;
    }
    #cs-navigation .cs-button-solid:before {
      content: "";
      width: 0%;
      height: 100%;
      background: #000;
      opacity: 1;
      border-radius: 0.25rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width 0.3s;
    }
    #cs-navigation .cs-button-solid:hover:before {
      width: 100%;
    }
  }

  .tiny-logo {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    font-weight: 100;
    color: rgb(46, 46, 46);
    letter-spacing: -2px;  /* Adjust this if needed to match the logo */
    text-decoration: none;
    margin: 0 auto 0 0;
    width: 100%;
    height: 100%;
    /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
    object-fit: contain;
}
.tiny-logo-footer{
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 100;
  color: rgb(255, 255, 255);
  letter-spacing: -2px;  /* Adjust this if needed to match the logo */
  text-decoration: none;
  margin: 0 auto 0 0;
  width: 100%;
  height: 100%;
  /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
  object-fit: contain;
}
